import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
} from '@material-ui/core'
import styled from 'styled-components'

export const AccordionHeader = styled(Typography)`
  && {
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
    text-transform: uppercase;
  }
`

export const Accordion = styled(MuiAccordion)`
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  && {
    box-shadow: none;
  }
  &.MuiAccordion-rounded:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom: none;
  }
  &.MuiAccordion-rounded:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &.MuiAccordion-root:before {
    display: none;
  }
`

export const AccordionDetails = styled(MuiAccordionDetails)`
  && {
    margin-bottom: 10px;
  }
`

export const AccordionSummary = styled(MuiAccordionSummary)`
  &&.MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px;
  }
`
