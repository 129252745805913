import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  background-color: ${({ theme }) => theme.colors.coolGray.cool100};
  overflow: hidden;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  @media (min-width: 768px) {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  @media (min-width: 992px) {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
`

export const ScrollableContainer = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
  gap: 20px;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const Title = styled.h1`
  font-size: 28px;
  margin-top: 2rem;
  @media (min-width: 992px) {
    margin-left: 0;
    margin-right: 0;
  }
  @media (min-width: 1440px) {
    font-size: 32px;
  }
`

export const Subtitle = styled.p`
  font-size: 18px;
  margin-top: 0.5rem;
  @media (min-width: 992px) {
    margin-left: 0;
    margin-right: 0;
  }
  @media (min-width: 1440px) {
    font-size: 16px;
  }
`
