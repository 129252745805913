import { graphql } from 'gatsby'
import React, { FC } from 'react'

import { ProductPage, ProductPageProps } from '../../components/shop/pdp/ProductPage'
import { withIsDispensaryPageContext } from '../../hoc/withIsDispensaryPageContext'

export type ProductPageTemplateProps = ProductPageProps

const ProductPageTemplate: FC<ProductPageTemplateProps> = ({ pageContext, data }) => {
  return <ProductPage data={data} pageContext={pageContext} />
}

export const query = graphql`
  query productPageTemplate($parentProductId: String!) {
    sanityParentProduct(id: { eq: $parentProductId }) {
      ...sanityParentProduct
    }
  }
`

export default withIsDispensaryPageContext(ProductPageTemplate)
