import { css, StyledProps } from 'styled-components'

import { SanityPageSectionStyles, SanitySpacerSection } from '../../graphql/gatsby'
import { Maybe } from '../../utils/types'
import { SanityBannerWithTextFiltered } from './BannerWithText/types'

function multiplierFrom(selectedSpacing: string) {
  switch (selectedSpacing) {
    case '0':
      return 0
    case '1':
      return 0.25
    case '2':
      return 0.5
    case '3':
      return 1
    case '4':
      return 2
    case '5':
      return 4
    default:
      return 1
  }
}

export function pageSectionStylesFor(
  props: Maybe<SanityPageSectionStyles> | undefined,
): ReturnType<typeof css> {
  const { marginBottom, marginTop, paddingBottom, paddingTop } = props ?? {}
  const baseSpacing = 16
  return css`
    overflow: hidden;
    margin-bottom: ${multiplierFrom(marginBottom || '0') * baseSpacing}px;
    margin-top: ${multiplierFrom(marginTop || '0') * baseSpacing}px;
    padding-bottom: ${multiplierFrom(paddingBottom || '3') * baseSpacing}px;
    padding-top: ${multiplierFrom(paddingTop || '3') * baseSpacing}px;
  `
}

export function textStylesFor(
  props: SanityBannerWithTextFiltered['textStyles'],
): ReturnType<typeof css> {
  if (!props) {
    return css``
  }
  const { textAlign, textColor, textColorV2, headerColor } = props
  return css`
    text-align: ${textAlign || 'left'};
    ${textColorV2?.color
      ? css`
          color: ${textColorV2.color};
        `
      : textColor
        ? css`
            color: ${textColor};
          `
        : css``}
    ${headerColor?.color
      ? css`
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: ${headerColor.color};
          }
        `
      : css``}
  `
}

export function backgroundStylesFor(
  props: SanityBannerWithTextFiltered['backgroundStyles'],
): ReturnType<typeof css> {
  const { backgroundColor } = props ?? {}
  return css`
    --section-bg-color: ${({ theme }) => backgroundColor?.color ?? theme.colors.grayscale.white};
    background-color: var(--section-bg-color);
  `
}

export interface BackgroundStylesMixin {
  $backgroundStyles: SanityBannerWithTextFiltered['backgroundStyles']
}

export const backgroundStyles = (props: BackgroundStylesMixin): ReturnType<typeof css> =>
  backgroundStylesFor(props.$backgroundStyles)

export function spacerSectionStylesFor(
  props: Maybe<SanitySpacerSection> | undefined,
): ReturnType<typeof css> {
  const { size } = props ?? {}
  const baseSpacing = 16
  return css`
    overflow: hidden;
    padding-top: ${multiplierFrom(size || '3') * baseSpacing}px;
  `
}

export const ctaTextColorOrDefault = ({
  $ctaTextColor,
  theme,
}: StyledProps<{
  $ctaTextColor: SanityBannerWithTextFiltered['ctaTextColor']
}>): string => $ctaTextColor?.color || theme.colors.grayscale.white

export const ctaBackgroundColorOrDefault = ({
  $ctaBackgroundColor,
  theme,
}: StyledProps<{
  $ctaBackgroundColor: SanityBannerWithTextFiltered['ctaBackgroundColor']
}>): string => $ctaBackgroundColor?.color || theme.colors.component.backgroundDarkBlue

export const fadeBackgroundInFromAbove = (
  length: number,
  color: string,
): ReturnType<typeof css> => css`
  margin-top: -${length}px;
  padding-top: ${length}px;
  background: linear-gradient(180deg, ${color}00 0%, ${color}ff ${length}px, ${color}ff 100%);
`
