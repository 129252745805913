import React, { FC } from 'react'
import styled from 'styled-components'

import { SanityParentProductFragment } from '../../../graphql/gatsby'
import {
  ConfigurableProductDetailsFragment,
  SimpleProductDetailsFragment,
} from '../../../graphql/magento'
import { dataSheets } from '../../../utils/itemTools'
import DataSheetLink from './DataSheetLink'

export interface DataSheetLinksProps {
  className?: string
  item: ConfigurableProductDetailsFragment
  sanityParentProduct?: SanityParentProductFragment | null
  selectedVariant: SimpleProductDetailsFragment | null | undefined
}

const DataSheetLinks: FC<DataSheetLinksProps> = ({
  className = '',
  item,
  selectedVariant = null,
  sanityParentProduct,
}) => {
  const dataSheetLinkColor = sanityParentProduct?.primaryColor?.color ?? undefined
  return (
    <span className={className}>
      {dataSheets({ item, selectedVariant }).map((dataSheet) => (
        <DataSheetLink
          key={dataSheet.text}
          {...dataSheet}
          $dataSheetLinkColor={dataSheetLinkColor}
        />
      ))}
    </span>
  )
}

const StyledDataSheetLinks = styled(DataSheetLinks)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginBottom: '24px',
})

export default StyledDataSheetLinks
