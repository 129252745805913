import React, { FC } from 'react'
import styled from 'styled-components'

import {
  ConfigurableProductDetailsFragment,
  SimpleProductDetailsFragment,
} from '../../../../graphql/magento'
import { displayAttribute } from '../../../../utils/itemTools'

export interface ItemAdvisorTagsProps {
  className?: string
  item: ConfigurableProductDetailsFragment
  selectedVariant: SimpleProductDetailsFragment | null | undefined
}

const ItemAdvisorTags: FC<ItemAdvisorTagsProps> = ({
  className = '',
  item,
  selectedVariant = null,
}) => {
  const advisorTags = displayAttribute({
    item,
    selectedVariant,
    attribute: 'advisor_tags',
  })

  if (advisorTags?.length) {
    return (
      <div className={`robotoCondensed ${className}`}>
        {advisorTags.map((advisorTag) => (
          <span className="advisor-tag-element" key={advisorTag}>
            {advisorTag}
          </span>
        ))}
      </div>
    )
  }

  return null
}

const StyledItemAdvisorTags = styled(ItemAdvisorTags)({
  fontSize: '12px',
  fontWeight: 'bold',
  letterSpacing: '0.06em',
  lineHeight: '14px',
  marginBottom: '25px',
  textTransform: 'uppercase',
  '.advisor-tag-element': {
    marginRight: '10px',
    '&:before': {
      content: '"•"',
      marginRight: '3px',
    },
    '&:last-of-type': {
      marginRight: '0',
    },
  },
})

export default StyledItemAdvisorTags
