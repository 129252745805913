import { Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import * as React from 'react'

import * as Styled from './styled'

export interface BasicAccordionProps {
  className?: string
  title: string
  children: React.ReactNode
  defaultExpanded?: boolean
}

const BasicAccordion: React.FC<BasicAccordionProps> = ({
  title,
  children,
  defaultExpanded = false,
}) => {
  return (
    <Styled.Accordion defaultExpanded={defaultExpanded}>
      <Styled.AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Styled.AccordionHeader variant="h5">{title}</Styled.AccordionHeader>
      </Styled.AccordionSummary>
      <Styled.AccordionDetails>
        <Typography variant="body2">{children}</Typography>
      </Styled.AccordionDetails>
    </Styled.Accordion>
  )
}
export default BasicAccordion
