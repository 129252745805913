import React from 'react'
import styled from 'styled-components'

import { Maybe, ProductProtocolsDocument, ProtocolResource, Slug } from '../../../graphql/api'
import { SimpleProductDetailsFragment } from '../../../graphql/magento'
import { useApiQuery } from '../../../hooks/useApiQuery'
import useLogAndCaptureError from '../../../hooks/useLogAndCaptureError'
import { getResourceAssetUrl } from '../../../lib/library'
import { isNotNull } from '../../../utils/collectionTools'
import BasicAccordion from '../../global/BasicAccordion'

const StyledProtocolLink = styled.a`
  color: ${({ theme }) => theme.colors.brand.orange};
  font-family: ${({ theme }) => theme.fontFamilies.roboto};
`

const StyledProtocolList = styled.ul`
  margin-block-start: 0;
  list-style-type: none;
  padding-inline-start: 20px;
`

const StyledProtocolListEntry = styled.li`
  margin-bottom: 1rem;
  @media (min-width: 600px) {
    margin-bottom: 0.5rem;
  }
`

export interface ProtocolsWithProductProps {
  selectedVariant: SimpleProductDetailsFragment | null | undefined
}

type ProtocolResourceLink =
  | Maybe<
      { __typename?: 'ProtocolResource' | undefined } & Pick<ProtocolResource, 'title'> & {
          slug?: Maybe<{ __typename?: 'Slug' | undefined } & Pick<Slug, 'current'>> | undefined
        }
    >
  | undefined

const sortByTitle = (a: ProtocolResourceLink, b: ProtocolResourceLink) => {
  if (a?.title && b?.title) {
    return a.title.localeCompare(b.title)
  }
  // default return value (no sorting)
  return 0
}

export const ProtocolsWithProduct: React.FC<ProtocolsWithProductProps> = ({
  selectedVariant = null,
}) => {
  const { data, error } = useApiQuery(ProductProtocolsDocument, {
    variables: { sku: selectedVariant?.sku || '' },
    skipWithoutToken: true,
  })
  useLogAndCaptureError(error)

  const protocolLinks = data?.allProduct?.[0]?.protocols
  if (!protocolLinks?.length) return null

  const sortedLinks = protocolLinks?.filter(isNotNull).sort(sortByTitle)
  if (!sortedLinks?.length) return null

  return (
    <BasicAccordion title="Protocols With This Product">
      <StyledProtocolList>
        {sortedLinks.map((link, index) => (
          <StyledProtocolListEntry key={index}>
            <StyledProtocolLink
              href={getResourceAssetUrl({
                _type: 'protocol',
                slug: { current: link?.slug?.current || '' },
              })}
              target="_blank"
              rel="noreferrer"
            >
              {link.title}
            </StyledProtocolLink>
          </StyledProtocolListEntry>
        ))}
      </StyledProtocolList>
    </BasicAccordion>
  )
}
