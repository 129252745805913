import React, { FC } from 'react'
import styled from 'styled-components'

import useUserCategory from '../../../hooks/useUserCategory'
import { dataSheetImage } from '../../../utils/itemTools'
import { userCategories, UserCategory } from '../../../utils/userClaims'
import Link from '../../global/Link'

export interface DataSheetLinkProps {
  className?: string
  href?: string | null | undefined
  rel?: string
  target?: string
  text: string
}

const professionalCategories: UserCategory[] = [
  userCategories.practitioner,
  userCategories.otherProfessional,
  userCategories.employee,
]

const DataSheetLink: FC<DataSheetLinkProps> = ({
  className = '',
  href = null,
  rel = 'noopener',
  target = '_blank',
  text,
}) => {
  const { userCategory } = useUserCategory()
  const isProfessional = userCategory && professionalCategories.indexOf(userCategory) > -1
  if (!href || (text === 'Tech Sheet' && !isProfessional)) {
    return null
  }
  return (
    <div className={className}>
      <img alt={text} src={dataSheetImage()} />
      <Link className={className} to={href} rel={rel} target={target}>
        {text}
      </Link>
    </div>
  )
}

const StyledDataSheetLink = styled(DataSheetLink)<{ $dataSheetLinkColor?: string }>(
  ({ theme, $dataSheetLinkColor }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontSize: '14px',
    lineHeight: '20px',
    textDecoration: 'underline',
    verticalAlign: 'middle',
    img: {
      height: '20px',
      marginRight: '8px',
    },
    '&:not(:last-of-type)': {
      marginRight: '30px',
    },
    a: {
      '&, &:visited': {
        color: $dataSheetLinkColor ?? theme.colors.grayscale.black,
      },
    },
  }),
)

export default StyledDataSheetLink
